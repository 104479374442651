<template>
  <v-card>
    <v-toolbar
      v-if="$store.state.loginModule.isAdmin"
      color="#F9F7F7"
      dense
      class="mb-2"
    >
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="green"
            v-bind="attrs"
            @click="openEditDialog()"
            v-on="on"
          >
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="red"
            v-bind="attrs"
            @click="deleteParticipant"
            v-on="on"
          >
            <v-icon>mdi-delete-forever</v-icon>
          </v-btn>
        </template>
        <span>Löschen</span>
      </v-tooltip>
    </v-toolbar>
    <v-row>
      <v-col class="d-flex text-center">
        <v-scroll-y-transition mode="out-in">
          <v-card :key="child.id" class="pt-6 mx-auto" width="90%" flat>
            <v-card-text>
              <v-avatar size="88">
                <v-img
                  :src="
                    `https://avataaars.io/${$options.filters.genderAvatar(
                      child.gender
                    )}`
                  "
                  class="mb-6"
                ></v-img>
              </v-avatar>
              <h3 class="headline mb-2">
                {{ child.firstName }} {{ child.familyName }}
              </h3>
              <v-row>
                <v-col cols="1">
                  <v-avatar size="24" color="primary" style="color: white">
                    {{ child | avatarFilter() }}
                  </v-avatar>
                </v-col>
                <v-col cols="3" class="blue--text mb-2">
                  {{ child.gender | genderFilter() }}
                </v-col>
                <v-col cols="4" class="blue--text mb-2">
                  {{ child.birthDay | birthDayFilter }}
                </v-col>
                <v-col cols="4" class="blue--text mb-2">
                  {{ child.attendance | attendanceFilter() }}
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex text-center">
        <v-scroll-y-transition mode="out-in">
          <v-card :key="child.id" class="mx-auto" width="90%" flat>
            <div class="zBackground">
              <v-card-title class="justify-center pa-0"
                >Medizinische Daten</v-card-title
              >
              <v-card-text>
                <divider-line text="Diabetes" />
                <two-column-block
                  :my-obj="{
                    l1: 'Behandelnder Diabetologe/-in',
                    v1: child.medicData.doctor.name,
                    l2: 'Praxis / Klinik',
                    v2: child.medicData.doctor.surgery
                  }"
                />
                <two-column-block
                  :my-obj="{
                    l1: 'Diabetes seit',
                    v1: $options.filters.diabetesSinceFilter(
                      child.medicData.doctor.diabetesSince
                    ),
                    l2: 'Therapieform',
                    v2: $options.filters.therapieFilter(
                      child.medicData.doctor.therapie,
                      child.medicData.doctor.pumpSince
                    )
                  }"
                />

                <divider-line text="Allergien & Unverträglichkeiten" />

                <v-row class="justify-center">
                  <flaged-label
                    v-if="child.medicData.allergies.celiac"
                    :my-obj="{ l1: 'Zöliakie' }"
                  />
                  <flaged-label
                    v-if="child.medicData.allergies.lactose"
                    :my-obj="{ l1: 'Laktose-Intoleranz' }"
                  />
                </v-row>
                <v-row class="justify-center">
                  <flaged-label
                    v-if="child.medicData.allergies.nut.flag"
                    :my-obj="{
                      l1: 'Nussallergie',
                      v1: child.medicData.allergies.nut.data
                        ? child.medicData.allergies.nut.data
                        : 'keine Angaben'
                    }"
                  />
                  <flaged-label
                    v-if="child.medicData.allergies.fruit.flag"
                    :my-obj="{
                      l1: 'Obst-Unverträglichkeit',
                      v1: child.medicData.allergies.fruit.data
                        ? child.medicData.allergies.fruit.data
                        : 'keine Angaben'
                    }"
                  />
                </v-row>

                <divider-line text="Andere Erkrankungen" />

                <v-row class="justify-center">
                  <flaged-label
                    v-if="child.medicData.dieseases.epilepsy"
                    :my-obj="{ l1: 'Epilepsie' }"
                  />
                  <flaged-label
                    v-if="child.medicData.dieseases.thyroid"
                    :my-obj="{ l1: 'Schilddrüsenerkrankung' }"
                  />
                  <flaged-label
                    v-if="child.medicData.dieseases.misc.flag"
                    :my-obj="{
                      l1: 'Sonstige Erkrankungen',
                      v1: child.medicData.dieseases.misc.data
                        ? child.medicData.dieseases.misc.data
                        : 'keine Angaben'
                    }"
                  />
                </v-row>
              </v-card-text>
            </div>
            <!-- Organizational -->
            <div>
              <v-card-title class="justify-center">Camp-Daten</v-card-title>
              <v-row>
                <v-col>
                  <v-card-subtitle>Gruppe</v-card-subtitle>
                  <v-divider class="mx-8" />
                  <v-card-text>
                    {{
                      child.campData && child.campData.group
                        ? child.campData.group
                        : 'nicht zugewiesen'
                    }}
                  </v-card-text>
                </v-col>
                <v-col>
                  <v-card-subtitle>Zimmer</v-card-subtitle>
                  <v-divider class="mx-8" />
                  <v-card-text>
                    {{
                      child.campData && child.campData.room
                        ? child.campData.room
                        : 'nicht zugewiesen'
                    }}
                  </v-card-text>
                </v-col>
                <v-col>
                  <v-card-subtitle>Bett</v-card-subtitle>
                  <v-divider class="mx-8" />
                  <v-card-text>
                    {{
                      child.campData && child.campData.bed
                        ? child.campData.bed
                        : 'nicht zugewiesen'
                    }}
                  </v-card-text>
                </v-col>
              </v-row>
            </div>
            <div class="zBackground">
              <v-card-title class="justify-center"
                >Sorgeberechtigte</v-card-title
              >
              <v-card-text>
                <two-column-block
                  :my-obj="{
                    l1: 'Nachname',
                    v1: child.parentData.familyName,
                    l2: 'Sorgeberechtigte(r)',
                    v2: child.parentData.sorgType
                  }"
                />
                <two-column-block
                  :my-obj="{
                    l1: 'Vorname',
                    v1: child.parentData.firstName,
                    l2: 'Telefon',
                    v2: child.parentData.phone
                  }"
                />
                <two-column-block
                  :my-obj="{
                    l1: 'eMail',
                    v1: child.parentData.email,
                    l2: 'Wohnort',
                    v2: child.parentData.city
                  }"
                />
                <two-column-block
                  :my-obj="{
                    l1: 'PLZ',
                    v1: child.parentData.zip,
                    l2: 'Strasse',
                    v2: child.parentData.street
                  }"
                />
              </v-card-text>
            </div>
            <div v-if="child.additionalData">
              <v-card-title class="justify-center"
                >Zusätzliche Information</v-card-title
              >
              <v-card-text>{{ child.additionalData }}</v-card-text>
            </div>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment';
import { Registrant } from '../../../plugins/classes/Classes';
import DividerLine from '../../elements/DividerLine.vue';
import TwoColumnBlock from '../../elements/TwoColumnBlock.vue';
import FlagedLabel from '../../elements/FlagedLabel.vue';

export default {
  components: { DividerLine, TwoColumnBlock, FlagedLabel },

  filters: {
    genderFilter(value) {
      if (!value) {
        return '';
      }
      if (value === 'male') {
        return 'männlich';
      }
      return 'weiblich';
    },

    attendanceFilter(value) {
      if (!value || value === 0) {
        return '1.Teilnahme';
      }
      return `${value + 1}. Teilnahme`;
    },

    birthDayFilter(value) {
      return `${value} (${moment().diff(
        moment(value, 'DD.MM.YYYY'),
        'years'
      )} Jahre)`;
    },

    genderAvatar(value) {
      if (!value) {
        return '?avatarStyle=Circle&topType=LongHairShavedSides&accessoriesType=Kurt&hairColor=Blonde&facialHairType=Blank&clotheType=Hoodie&clotheColor=PastelYellow&eyeType=Default&eyebrowType=Default&mouthType=Concerned&skinColor=Light';
      }
      if (value === 'male') {
        return '?avatarStyle=Circle&topType=ShortHairShortFlat&accessoriesType=Sunglasses&hairColor=Brown&facialHairType=Blank&clotheType=Hoodie&clotheColor=Gray01&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light';
      }
      return '?avatarStyle=Circle&topType=LongHairStraight&accessoriesType=Blank&hairColor=Blonde&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light';
    },

    avatarFilter(value) {
      if (!value) {
        return '';
      }

      return (
        value.firstName.charAt(0).toUpperCase() +
        value.familyName.charAt(0).toUpperCase()
      );
    },

    diabetesSinceFilter(value) {
      if (value === 0) {
        return '-';
      }
      const txt = value === 1 ? 'Jahr' : 'Jahren';
      return `${value} ${txt}`;
    },

    therapieFilter(value, _pumpSince) {
      if (value === 'Spritze') {
        return value;
      }
      let pumpSince = _pumpSince;
      if (!pumpSince) {
        pumpSince = '?';
      }
      const txt = pumpSince === 1 ? 'Jahr' : 'Jahren';
      return `${value} (seit ${pumpSince} ${txt})`;
    }
  },
  computed: {
    child() {
      const allParticipants = this.$store.state.participantsModule.participants;
      let child = allParticipants.find(e => e.id === this.$route.params.id);

      if (!child) {
        // will be the case after deletion
        child = Registrant.getNew();
      }

      return child;
    }
  },

  methods: {
    deleteParticipant() {
      this.$root.$confirm
        .open({
          titel: 'Löschen',
          msg: `${this.child.firstName} wirklich löschen`
        })
        .then(response => {
          if (response) {
            const childName = this.child.firstName;
            this.$store
              .dispatch('ACT_DELETE_PARTICIPANT', this.child)
              .then(() => {
                this.$store.commit('MUT_IS_CHILD_SELECTED', false);
                this.$root.$info
                  .open({
                    title: 'Teilnehmer gelöscht',
                    msg: `${childName} erfolgreich gelöscht`
                  })
                  .then(() => {
                    this.$router.push({ path: '/participants' });
                  });
              })
              .catch(error => {
                this.$root.$info.open({ title: 'Mist', msg: error });
              });
          } else {
            this.showSnackBar('green', 'Löschen abgebrochen');
          }
        })
        .catch(error => {
          this.showSnackBar('red', error);
        });
    },

    openEditDialog() {
      this.$store.commit('MUT_IS_CHILD_SELECTED', true);
      this.$store.commit('MUT_IS_CHILDREN_LIST', false);
      this.$router.push(`/participants/edit/${this.child.id}`);
    }
  }
};
</script>

<style>
.zBackground {
  background-color: #f8f9f9;
}
</style>
