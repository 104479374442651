<template>
  <v-row align="center" class="my-4">
    <!-- <v-divider class="mx-4" style="max-width: 50px"></v-divider -->
    <v-divider class="ml-8 mr-4"></v-divider
    ><span class="caption green--text">{{ text }}</span
    ><v-divider class="ml-4 mr-8"></v-divider> </v-row
></template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }
};
</script>
