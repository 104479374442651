<template>
  <v-col cols="12" lg="3" md="3" sm="6" class="pl-0 pt-0">
    <v-icon color="red">
      mdi-check
    </v-icon>
    {{ myObj.l1 }}
    <br />
    <span v-if="myObj.v1" class="red--text">{{ myObj.v1 }}</span>
  </v-col>
</template>

<script>
export default {
  props: {
    myObj: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>
