<template>
  <v-row>
    <v-col cols="6">
      <v-row>
        <v-col cols="12" md="5" align="start" class="grey--text pa-0">
          {{ myObj.l1 }}:
        </v-col>
        <v-col cols="12" md="7" align="start" class="blue--text pa-0">
          {{ myObj.v1 }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row>
        <v-col cols="12" md="5" align="start" class="grey--text pa-0">
          {{ myObj.l2 }}:
        </v-col>
        <v-col cols="12" md="5" align="start" class="blue--text pa-0">
          {{ myObj.v2 }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    myObj: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>

<style></style>
