class RegistrantClass {
  constructor() {
    this.mockChild = {
      childData: {
        familyName: 'Mustermann',
        firstName: 'Max',
        birthDay: '20.03.2012',
        gender: 'male',
        attendance: 3
      },
      medicData: {
        doctor: {
          name: 'Dotore',
          surgery: 'Klinik',
          diabetesSince: 5,
          therapie: 'Pumpe',
          pumpSince: 3
        },
        allergies: {
          celiac: true,
          lactose: true,
          nut: {
            flag: true,
            data: 'Nuts'
          },
          fruit: {
            flag: true,
            data: 'Fruits'
          }
        },
        dieseases: {
          thyroid: true,
          epilepsy: true,
          misc: {
            flag: false,
            data: 'Misc'
          }
        }
      },
      parentData: {
        familyName: 'Schmidt',
        firstName: 'Gerda',
        phone: '49123456789',
        email: 'grexess@googlemail.com',
        zip: '01234',
        city: 'City',
        street: 'Street',
        sorgType: 'Vater'
      },
      additionalData: { additionalData: 'addData' }
    };

    /* because we have different structures of child in class and JS we dprovide a mixture here */
    // TODO
    this.emptyChild = {
      familyName: '',
      firstName: '',
      birthDay: '',
      gender: null,
      attendance: null,
      childData: {
        familyName: '',
        firstName: '',
        birthDay: '',
        gender: null,
        attendance: null
      },
      medicData: {
        doctor: {
          name: '',
          surgery: '',
          diabetesSince: null,
          therapie: null,
          pumpSince: null
        },
        allergies: {
          celiac: false,
          lactose: false,
          nut: {
            flag: false,
            data: ''
          },
          fruit: {
            flag: false,
            data: ''
          }
        },
        dieseases: {
          thyroid: false,
          epilepsy: false,
          misc: {
            flag: false,
            data: ''
          }
        }
      },
      parentData: {
        familyName: '',
        firstName: '',
        phone: '',
        email: '',
        zip: '',
        city: '',
        street: '',
        sorgType: null
      },
      additionalData: {
        additionalData: ''
      }
    };
  }

  getNew() {
    return this.emptyChild;
  }

  getMock() {
    return this.mockChild;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const Registrant = new RegistrantClass();
