var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.$store.state.loginModule.isAdmin)?_c('v-toolbar',{staticClass:"mb-2",attrs:{"color":"#F9F7F7","dense":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.openEditDialog()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}],null,false,1167214598)},[_c('span',[_vm._v("Bearbeiten")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":_vm.deleteParticipant}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-forever")])],1)]}}],null,false,42540117)},[_c('span',[_vm._v("Löschen")])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex text-center"},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[_c('v-card',{key:_vm.child.id,staticClass:"pt-6 mx-auto",attrs:{"width":"90%","flat":""}},[_c('v-card-text',[_c('v-avatar',{attrs:{"size":"88"}},[_c('v-img',{staticClass:"mb-6",attrs:{"src":("https://avataaars.io/" + (_vm.$options.filters.genderAvatar(
                    _vm.child.gender
                  )))}})],1),_c('h3',{staticClass:"headline mb-2"},[_vm._v(" "+_vm._s(_vm.child.firstName)+" "+_vm._s(_vm.child.familyName)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{staticStyle:{"color":"white"},attrs:{"size":"24","color":"primary"}},[_vm._v(" "+_vm._s(_vm._f("avatarFilter")(_vm.child))+" ")])],1),_c('v-col',{staticClass:"blue--text mb-2",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm._f("genderFilter")(_vm.child.gender))+" ")]),_c('v-col',{staticClass:"blue--text mb-2",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm._f("birthDayFilter")(_vm.child.birthDay))+" ")]),_c('v-col',{staticClass:"blue--text mb-2",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm._f("attendanceFilter")(_vm.child.attendance))+" ")])],1)],1),_c('v-divider')],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex text-center"},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[_c('v-card',{key:_vm.child.id,staticClass:"mx-auto",attrs:{"width":"90%","flat":""}},[_c('div',{staticClass:"zBackground"},[_c('v-card-title',{staticClass:"justify-center pa-0"},[_vm._v("Medizinische Daten")]),_c('v-card-text',[_c('divider-line',{attrs:{"text":"Diabetes"}}),_c('two-column-block',{attrs:{"my-obj":{
                  l1: 'Behandelnder Diabetologe/-in',
                  v1: _vm.child.medicData.doctor.name,
                  l2: 'Praxis / Klinik',
                  v2: _vm.child.medicData.doctor.surgery
                }}}),_c('two-column-block',{attrs:{"my-obj":{
                  l1: 'Diabetes seit',
                  v1: _vm.$options.filters.diabetesSinceFilter(
                    _vm.child.medicData.doctor.diabetesSince
                  ),
                  l2: 'Therapieform',
                  v2: _vm.$options.filters.therapieFilter(
                    _vm.child.medicData.doctor.therapie,
                    _vm.child.medicData.doctor.pumpSince
                  )
                }}}),_c('divider-line',{attrs:{"text":"Allergien & Unverträglichkeiten"}}),_c('v-row',{staticClass:"justify-center"},[(_vm.child.medicData.allergies.celiac)?_c('flaged-label',{attrs:{"my-obj":{ l1: 'Zöliakie' }}}):_vm._e(),(_vm.child.medicData.allergies.lactose)?_c('flaged-label',{attrs:{"my-obj":{ l1: 'Laktose-Intoleranz' }}}):_vm._e()],1),_c('v-row',{staticClass:"justify-center"},[(_vm.child.medicData.allergies.nut.flag)?_c('flaged-label',{attrs:{"my-obj":{
                    l1: 'Nussallergie',
                    v1: _vm.child.medicData.allergies.nut.data
                      ? _vm.child.medicData.allergies.nut.data
                      : 'keine Angaben'
                  }}}):_vm._e(),(_vm.child.medicData.allergies.fruit.flag)?_c('flaged-label',{attrs:{"my-obj":{
                    l1: 'Obst-Unverträglichkeit',
                    v1: _vm.child.medicData.allergies.fruit.data
                      ? _vm.child.medicData.allergies.fruit.data
                      : 'keine Angaben'
                  }}}):_vm._e()],1),_c('divider-line',{attrs:{"text":"Andere Erkrankungen"}}),_c('v-row',{staticClass:"justify-center"},[(_vm.child.medicData.dieseases.epilepsy)?_c('flaged-label',{attrs:{"my-obj":{ l1: 'Epilepsie' }}}):_vm._e(),(_vm.child.medicData.dieseases.thyroid)?_c('flaged-label',{attrs:{"my-obj":{ l1: 'Schilddrüsenerkrankung' }}}):_vm._e(),(_vm.child.medicData.dieseases.misc.flag)?_c('flaged-label',{attrs:{"my-obj":{
                    l1: 'Sonstige Erkrankungen',
                    v1: _vm.child.medicData.dieseases.misc.data
                      ? _vm.child.medicData.dieseases.misc.data
                      : 'keine Angaben'
                  }}}):_vm._e()],1)],1)],1),_c('div',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Camp-Daten")]),_c('v-row',[_c('v-col',[_c('v-card-subtitle',[_vm._v("Gruppe")]),_c('v-divider',{staticClass:"mx-8"}),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.child.campData && _vm.child.campData.group ? _vm.child.campData.group : 'nicht zugewiesen')+" ")])],1),_c('v-col',[_c('v-card-subtitle',[_vm._v("Zimmer")]),_c('v-divider',{staticClass:"mx-8"}),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.child.campData && _vm.child.campData.room ? _vm.child.campData.room : 'nicht zugewiesen')+" ")])],1),_c('v-col',[_c('v-card-subtitle',[_vm._v("Bett")]),_c('v-divider',{staticClass:"mx-8"}),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.child.campData && _vm.child.campData.bed ? _vm.child.campData.bed : 'nicht zugewiesen')+" ")])],1)],1)],1),_c('div',{staticClass:"zBackground"},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Sorgeberechtigte")]),_c('v-card-text',[_c('two-column-block',{attrs:{"my-obj":{
                  l1: 'Nachname',
                  v1: _vm.child.parentData.familyName,
                  l2: 'Sorgeberechtigte(r)',
                  v2: _vm.child.parentData.sorgType
                }}}),_c('two-column-block',{attrs:{"my-obj":{
                  l1: 'Vorname',
                  v1: _vm.child.parentData.firstName,
                  l2: 'Telefon',
                  v2: _vm.child.parentData.phone
                }}}),_c('two-column-block',{attrs:{"my-obj":{
                  l1: 'eMail',
                  v1: _vm.child.parentData.email,
                  l2: 'Wohnort',
                  v2: _vm.child.parentData.city
                }}}),_c('two-column-block',{attrs:{"my-obj":{
                  l1: 'PLZ',
                  v1: _vm.child.parentData.zip,
                  l2: 'Strasse',
                  v2: _vm.child.parentData.street
                }}})],1)],1),(_vm.child.additionalData)?_c('div',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Zusätzliche Information")]),_c('v-card-text',[_vm._v(_vm._s(_vm.child.additionalData))])],1):_vm._e()])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }